// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contato-index-js": () => import("/opt/build/repo/src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-equipamentos-index-js": () => import("/opt/build/repo/src/pages/equipamentos/index.js" /* webpackChunkName: "component---src-pages-equipamentos-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-index-js": () => import("/opt/build/repo/src/pages/sobre/index.js" /* webpackChunkName: "component---src-pages-sobre-index-js" */),
  "component---src-pages-sucesso-index-js": () => import("/opt/build/repo/src/pages/sucesso/index.js" /* webpackChunkName: "component---src-pages-sucesso-index-js" */)
}

